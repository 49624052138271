const formatInputDecimal = (val: any) => {
    const numberValue = val.toString()
      .replace(/[^0-9,.]/g, "")
      .replace(/[,.]+/g, ".")
      .replace(/,(\d+),/g, ",$1")
    
    if (!val) return "0";
    if (numberValue.includes(".")) {
      return numberValue
    } else {
      return Number(numberValue);
    }
    
  };
export default formatInputDecimal;
  