/* eslint-disable @typescript-eslint/camelcase */
import { container } from "tsyringe";
import { VuexModule, Module, Action, getModule } from "vuex-module-decorators";
import store from "@/store";
import { CodConfigurationPresenter } from "../presenters/CodConfigurationPresenter";
import { ApiRequestList } from "@/domain/entities/MainApp";
import { SubmitCodConfigurationApiRequest } from "@/data/payload/api/CodConfigurationApiRequest";

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: "cod-configuration"
})
class CodConfigurationStore extends VuexModule {
  @Action
  public getList(params: ApiRequestList) {
    const presenter = container.resolve(CodConfigurationPresenter);
    return presenter.getList(params);
  }

  @Action
  public getDetailCodConfigurationRetail(id: number) {
    const presenter = container.resolve(CodConfigurationPresenter);
    return presenter.getDetailCodConfiguration(id);
  }

  @Action
  public async editCodConfiguration(
    payload: SubmitCodConfigurationApiRequest
  ) {
    const presenter = container.resolve(CodConfigurationPresenter);
    return presenter.editCodConfiguration(payload);
  }
}

export const CodConfigurationController = getModule(CodConfigurationStore);
