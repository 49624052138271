
import { Vue } from "vue-class-component";
import formatDate from "@/app/infrastructures/misc/common-library/FormatDate";
import parsingErrorResponse from "@/app/infrastructures/misc/common-library/ParsingErrorResponse";
import { ChipsStyles } from "@/app/ui/components/chips-v2/modules";
import { ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { DetailCodConfigurationRetail } from "@/domain/entities/CodConfiguration";
import { CodConfigurationController } from "@/app/ui/controllers/CodConfigurationController";
import { ApiRequestList, OptionsClass } from "@/domain/entities/MainApp";
import statusListData from "@/app/infrastructures/misc/common-library/StatusListData";
import { checkRolePermission } from "@/app/infrastructures/misc/Utils";
import { COD_RETAIL_CONFIGURATION } from "@/app/infrastructures/misc/RolePermission";

export default class List extends Vue {
  mounted() {
    this.fetchList();
  }
  get title(): string {
    return (this.$route as any)?.meta?.title;
  }

  get statusData(): OptionsClass[] {
    return statusListData(["active", "inactive"]);
  }

  get isDetailAble() {
    return checkRolePermission(COD_RETAIL_CONFIGURATION.DETAIL);
  }
  onSelectStatus(item: OptionsClass): void {
    this.requestData.statusData = item;
    this.listData.pagination.page = 1;
    this.fetchList();
  }

  requestData: ApiRequestList = new ApiRequestList();
  listData: ResponsePayloadV2 = new ResponsePayloadV2();
  async fetchList() {
    this.listData.loading = true;
    try {
      this.listData = await CodConfigurationController.getList(
        new ApiRequestList({
          page: this.listData.pagination.page,
          limit: this.listData.pagination.limit,
          status: this.requestData.statusData.value
        })
      );
    } catch (err) {
      this.listData.message = parsingErrorResponse(err).type;
    } finally {
      this.listData.loading = false;
    }
  }

  columns = [
    {
      name: "ID",
      styleHead: "w-1/20 text-left whitespace-no-wrap",
      render: (item: DetailCodConfigurationRetail) =>
        `<span class='text-left text-red-lp-200 flex justify-center'>${item.ccoId}</span>`
    },
    {
      name: "Nama Konfigurasi",
      styleHead: "w-1/4 text-left whitespace-no-wrap",
      render: (item: DetailCodConfigurationRetail) =>
        `<span class='text-left text-black-lp-300 flex justify-center'>${item.ccoName}</span>`
    },
    {
      name: "Dibuat Oleh",
      styleHead: "w-3/20 text-left whitespace-no-wrap",
      render: (item: DetailCodConfigurationRetail) =>
        `<span class='text-left text-black-lp-300 flex justify-center capitalize'>${item.ccoCreatedBy ||
          "-"}</span>`
    },
    {
      name: "Tanggal Dibuat",
      styleHead: "w-3/20 text-left whitespace-no-wrap",
      render: (item: DetailCodConfigurationRetail) =>
        `<span class='text-black-lp-300 flex justify-center'>${formatDate(
          item.ccoCreatedAt
        ) || "-"}</span>`
    },
    {
      name: "Diperbarui Oleh",
      styleHead: "w-3/20 text-left whitespace-no-wrap",
      render: (item: DetailCodConfigurationRetail) =>
        `<span class='text-black-lp-300 flex justify-center capitalize'>${item.ccoUpdatedBy ||
          "-"}</span>`
    },
    {
      name: "Terakhir Diperbarui",
      styleHead: "w-3/20 text-left whitespace-no-wrap",
      render: (item: DetailCodConfigurationRetail) =>
        `<span class='text-black-lp-300 flex justify-center'>${formatDate(
          item.ccoUpdatedAt
        ) || "-"}</span>`
    },
    {
      name: "Status",
      styleHead: "w-2/20 text-left text-black-lp-300 whitespace-no-wrap",
      render: (item: DetailCodConfigurationRetail) =>
        ChipsStyles({ status: item.ccoStatus })
    }
  ];


  goToDetail(item: any){
    this.$router.push(`/tarif/konfigurasi-cod-retail/${item.ccoId}`);
  }
}
