/* eslint-disable @typescript-eslint/camelcase */
export class SubmitCodConfigurationApiRequest {
    configId = 0;
    configName = "";
    configDescription = "";
    typeCodPrice = "";
    products = "";
    insurance = false;
    cityCode = "";
    cityName = "";
    priceMinimal = 0;
    priceMaximal = 0;
    ccoIsDfod = false;
    ccoOriginMinCommission = 0;
    ccoDestinationMinCommission = 0;
    precentageCodFee = 0;
    minimalCodFee = 0;
    commissionOriginPos = 0;
    commissionDestinationPos = 0;
    status = "";
    
    constructor(fields?: Partial<SubmitCodConfigurationApiRequest>) {
        Object.assign(this, fields);
    }

    toJSON(): string {
        return JSON.stringify({
            cco_id: this.configId,
            cco_name: this.configName,
            cco_description: this.configDescription,
            cco_product_type: this.products,
            cco_min_price: this.priceMinimal,
            cco_max_price: this.priceMaximal,
            cco_origin_min_commission: this.ccoOriginMinCommission,
            cco_destination_min_commission: this.ccoDestinationMinCommission,
            cco_is_dfod: this.ccoIsDfod,
            cco_min_cod_value: this.minimalCodFee,
            cco_cod_type: this.typeCodPrice,
            cco_is_insurance: this.insurance,
            cco_city_code: this.cityCode,
            cco_city_name: this.cityName,
            cco_percentage_cod: this.precentageCodFee,
            cco_origin_commission_percentage: this.commissionOriginPos,
            cco_destination_commission_percentage: this.commissionDestinationPos,
            cco_status: this.status
        });
    }
}