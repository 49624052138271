import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "space-y-6" }
const _hoisted_2 = { class: "flex flex-col sm:flex-row flex-wrap gap-y-6" }
const _hoisted_3 = { class: "flex flex-col sm:flex-row flex-wrap gap-y-6 mb-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DataWrapper = _resolveComponent("DataWrapper")!
  const _component_Chips = _resolveComponent("Chips")!
  const _component_ChipsV2 = _resolveComponent("ChipsV2")!
  const _component_LpButton = _resolveComponent("LpButton")!
  const _component_DetailLayout = _resolveComponent("DetailLayout")!

  return (_openBlock(), _createBlock(_component_DetailLayout, {
    scrollSidebar: "",
    customClass: "px-0",
    onBack: _ctx.goBack,
    title: _ctx.getTitle,
    loading: _ctx.isLoading,
    error: _ctx.isError,
    errorType: _ctx.errorCause,
    onTryAgain: _ctx.getDetail
  }, {
    data: _withCtx(() => [
      _createVNode("div", _hoisted_1, [
        _createVNode("div", _hoisted_2, [
          _createVNode(_component_DataWrapper, {
            label: "ID Konfigurasi",
            value: _ctx.detailData.ccoId,
            class: "w-12/12 sm:w-6/12"
          }, null, 8, ["value"]),
          _createVNode(_component_DataWrapper, {
            label: "Nama Konfigurasi",
            value: _ctx.detailData.ccoName,
            class: "w-12/12 sm:w-6/12"
          }, null, 8, ["value"])
        ]),
        _createVNode(_component_DataWrapper, {
          label: "Deskripsi Konfigurasi",
          value: _ctx.detailData.ccoDescription,
          class: "w-12/12 sm:w-4/12"
        }, null, 8, ["value"]),
        _createVNode(_component_DataWrapper, {
          label: "Tipe Total Harga COD",
          value: _ctx.handleTypeCod,
          class: "w-12/12 sm:w-4/12"
        }, null, 8, ["value"]),
        _createVNode(_component_DataWrapper, {
          label: "Jenis Pengiriman",
          value: _ctx.detailData.ccoProductType,
          class: "w-12/12 sm:w-4/12"
        }, null, 8, ["value"]),
        _createVNode(_component_DataWrapper, {
          label: "Asuransi",
          value: _ctx.detailData.ccoIsInsurance ? 'YA' : 'TIDAK' ,
          class: "w-12/12 sm:w-4/12"
        }, null, 8, ["value"]),
        _createVNode(_component_DataWrapper, { label: "Kota Penyedia Booking STT COD" }, {
          default: _withCtx(() => [
            _createVNode(_component_Chips, {
              label: _ctx.detailData.ccoCityCode
            }, null, 8, ["label"])
          ]),
          _: 1
        }),
        _createVNode("div", _hoisted_3, [
          _createVNode(_component_DataWrapper, {
            label: "Minimal Harga Barang",
            value: _ctx.convertMinPriceIDR,
            class: "w-12/12 sm:w-6/12"
          }, null, 8, ["value"]),
          _createVNode(_component_DataWrapper, {
            label: "Maksimal Harga Barang",
            value: _ctx.convertMaxPriceIDR,
            class: "w-12/12 sm:w-6/12"
          }, null, 8, ["value"]),
          _createVNode(_component_DataWrapper, {
            label: "Persentase Biaya COD",
            value: _ctx.detailData.ccoPercentageCod + '%',
            class: "w-12/12 sm:w-6/12"
          }, null, 8, ["value"]),
          _createVNode(_component_DataWrapper, {
            label: "Minimal Biaya COD",
            value: _ctx.convertMinPriceCOD,
            class: "w-12/12 sm:w-6/12"
          }, null, 8, ["value"]),
          _createVNode(_component_DataWrapper, {
            label: "Persentase Komisi COD POS Asal",
            value: _ctx.detailData.ccoOriginCommissionPercentage + '%',
            class: "w-12/12 sm:w-6/12"
          }, null, 8, ["value"]),
          _createVNode(_component_DataWrapper, {
            label: "Persentase Komisi COD POS Tujuan",
            value: _ctx.detailData.ccoDestinationCommissionPercentage + '%',
            class: "w-12/12 sm:w-6/12"
          }, null, 8, ["value"]),
          _createVNode(_component_DataWrapper, {
            label: "Minimal Komisi COD POS Asal",
            value: _ctx.convertMinCODOriginPOS,
            class: "w-12/12 sm:w-6/12"
          }, null, 8, ["value"]),
          _createVNode(_component_DataWrapper, {
            label: "Minimal Komisi COD POS Tujuan",
            value: _ctx.convertMinCODDestinationPOS,
            class: "w-12/12 sm:w-6/12"
          }, null, 8, ["value"])
        ])
      ])
    ]),
    "sub-data": _withCtx(() => [
      _createVNode(_component_DataWrapper, { label: "Status Konfigurasi Biaya" }, {
        default: _withCtx(() => [
          _createVNode(_component_ChipsV2, {
            label: _ctx.detailData.ccoStatus
          }, null, 8, ["label"])
        ]),
        _: 1
      }),
      _createVNode(_component_DataWrapper, {
        label: "Tanggal Dibuat",
        value: _ctx.convertCreatedAt,
        caption: _ctx.detailData.ccoCreatedBy
      }, null, 8, ["value", "caption"]),
      _createVNode(_component_DataWrapper, {
        label: "Tanggal Diperbarui",
        value: _ctx.convertUpdatedAt,
        caption: _ctx.detailData.ccoUpdatedBy
      }, null, 8, ["value", "caption"]),
      (_ctx.isEditAble && !_ctx.isSenderAcount)
        ? (_openBlock(), _createBlock(_component_LpButton, {
            key: 0,
            customClass: "px-1 sm:px-12 py-2.5 text-red-lp-100",
            outline: "",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.goToEdit(_ctx.$route.params.id))),
            title: "Ubah"
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["onBack", "title", "loading", "error", "errorType", "onTryAgain"]))
}