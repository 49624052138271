
import { Vue, Options } from "vue-class-component";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { ModalMessageEntities } from "@/domain/entities/MainApp";
import { LocationController } from "@/app/ui/controllers/LocationController";
import debounce from "lodash/debounce";
import formatInputNumberOnly from "@/app/infrastructures/misc/common-library/FormatInputNumberOnly";
import parsingErrorResponse from "@/app/infrastructures/misc/common-library/ParsingErrorResponse";
import formatInputDecimal from "@/app/infrastructures/misc/common-library/FormatInputDecimal";
import { CodConfigurationController } from "@/app/ui/controllers/CodConfigurationController";
import { SubmitCodConfigurationApiRequest } from "@/data/payload/api/CodConfigurationApiRequest";

interface Form {
  configId: number;
  configName: string;
  configDescription: string;
  typeCodPrice: string;
  products: string[];
  insurance: any;
  cities: any[];
  priceMinimal: string;
  priceMaximal: string;
  minCommissionCODOriginPOS: string;
  minCommissionCODDestinationPOS: string;
  isDfod: boolean;
  precentageCodFee: string;
  minimalCodFee: string;
  commissionOriginPos: string;
  commissionDestinationPos: string;
  status: boolean;
}

@Options({
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name === "login") {
      next();
    }
    if (this.answerLeavingPage) {
      next();
    } else {
      this.leavePageConfirmation = true;
      this.nextPath = to.path;
      next(false);
    }
  }
})
export default class Edit extends Vue {
  titlePage = "";
  get getTitle() {
    return this.titlePage;
  }
  leavePageConfirmation = false;
  answerLeavingPage = false;
  nextPath = "";
  goBack() {
    const path = `/tarif/konfigurasi-cod-retail/${this.$route.params.id}`;
    this.$router.push(path);
  }

  onLeavePage() {
    this.answerLeavingPage = true;
    this.$router.push(this.nextPath);
  }

  mounted() {
    this.fetchDetail();
  }

  isLoading = false;
  errorCause = "";
  async fetchDetail() {
    this.isLoading = true;
    this.errorCause = "";

    try {
      const detail = await CodConfigurationController.getDetailCodConfigurationRetail(
        Number(this.$route.params.id)
      );

      this.titlePage = detail.ccoName;

      this.form.configId = detail.ccoId;
      this.form.configName = detail.ccoName;
      this.form.configDescription = detail.ccoDescription;
      this.form.typeCodPrice = this.convertCodType(detail.ccoCodType);
      this.form.products = detail.ccoProductType
        ? detail.ccoProductType.split(",")
        : [];
      this.form.insurance = detail.ccoIsInsurance
        ? { name: "Ya", value: "yes" }
        : { name: "Tidak", value: "no" };

      const cityNames = detail.ccoCityName
        ? detail.ccoCityName.split(",").map((item: string) => item)
        : [];
      const allValue =
        detail.ccoCityCode === "ALL"
          ? [{ name: "Semua", value: "ALL" }]
          : detail.ccoCityCode.split(",").map((item: string, index: number) => {
              const nameOfCity = cityNames.length ? `- ${cityNames[index]}` : "";
              return {
                  name: `${item} ${nameOfCity}`,
                  value: item
              }
            });
      this.form.cities = detail.ccoCityCode ? allValue : [];
      this.form.priceMinimal = this.formatInputNumberAbleZero(
        String(detail.ccoMinPrice)
      );
      this.form.priceMaximal = this.formatInputNumberAbleZero(
        String(detail.ccoMaxPrice)
      );
      this.form.precentageCodFee = String(detail.ccoPercentageCod).replaceAll(
        ".",
        ","
      );
      this.form.minimalCodFee = this.formatInputNumberAbleZero(
        String(detail.ccoMinCodValue)
      );
      this.form.commissionOriginPos = String(
        detail.ccoOriginCommissionPercentage
      ).replaceAll(".", ",");
      this.form.commissionDestinationPos = String(
        detail.ccoDestinationCommissionPercentage
      ).replaceAll(".", ",");
      this.form.status = detail.ccoStatus === "active" ? true : false;
      this.form.minCommissionCODOriginPOS = this.formatInputNumberAbleZero(
        String(detail.ccoOriginMinCommission)
      );
      this.form.minCommissionCODDestinationPOS = this.formatInputNumberAbleZero(
        String(detail.ccoDestinationMinCommission)
      );
      this.form.isDfod = detail.ccoIsDfod
    } catch (error) {
      this.errorCause = parsingErrorResponse(error).type;
    } finally {
      this.isLoading = false;
    }
  }

  async onSaveForm() {
    MainAppController.showMessageModal(
      new ModalMessageEntities({
        title: `Simpan Perubahan “${this.form.configName}”?`,
        message:
          "Pastikan kembali, konfigurasi COD yang diperbarui sudah benar dan sesuai",
        textCancel: "Tidak",
        textSuccess: "Ya",
        onSubmit: () => this.submitDataPayload(),
        onClose: () => MainAppController.closeMessageModal(),
        image: "badge-confirmation-general"
      })
    );
  }

  get isFormValid() {
    return (
      !!this.form.configId &&
      !!this.form.configName &&
      !!this.form.configDescription &&
      !!this.form.typeCodPrice &&
      !!this.form.products.length &&
      !!this.form.insurance.value &&
      !!this.form.priceMinimal &&
      !!this.form.priceMaximal &&
      !!this.form.minCommissionCODOriginPOS &&
      !!this.form.minCommissionCODDestinationPOS &&
      !!this.form.precentageCodFee &&
      !!this.form.minimalCodFee &&
      !!this.form.commissionOriginPos &&
      !!this.form.commissionDestinationPos &&
      this.form.configDescription.length >= 3 &&
      !this.priceMinimalValidity.error &&
      !this.minCodFreeLargerThan.error &&
      !this.priceMaximalValidity.error &&
      !this.priceMaximalSmallerThan.error &&
      !this.error.percentageCodFee.error &&
      !this.error.commissionOriginPos.error &&
      !this.error.commissionOriginDestination.error
    );
  }

  async submitDataPayload() {
    MainAppController.closeErrorMessage();
    MainAppController.closeMessageModal();
    MainAppController.showLoading();

    try {
      const citiesAll = this.form.cities.find(
        (item: any) => item.value === "ALL"
      );
      await CodConfigurationController.editCodConfiguration(
        new SubmitCodConfigurationApiRequest({
          configId: this.form.configId,
          configName: this.form.configName,
          configDescription: this.form.configDescription,
          typeCodPrice:
            this.form.typeCodPrice === "Harga Barang"
              ? "goods_price"
              : "goods_price,total_tarif",
          products: this.form.products.join(),
          insurance: this.form.insurance.value === "yes" ? true : false,
          cityCode: citiesAll ? "ALL" : this.form.cities.map((item: any) => item.value).join(),
          cityName: citiesAll ? "ALL" : this.form.cities.map((item: any) => item.name.split(" - ")[1]).join().toUpperCase(),
          priceMinimal: Number(this.form.priceMinimal.replaceAll(".", "")),
          priceMaximal: Number(this.form.priceMaximal.replaceAll(".", "")),
          ccoIsDfod: this.form.isDfod,
          ccoOriginMinCommission: Number(this.form.minCommissionCODOriginPOS.replaceAll(".", "")),
          ccoDestinationMinCommission: Number(this.form.minCommissionCODDestinationPOS.replaceAll(".", "")),
          precentageCodFee: Number(
            this.form.precentageCodFee.replaceAll(",", ".")
          ),
          minimalCodFee: Number(this.form.minimalCodFee.replaceAll(".", "")),
          commissionOriginPos: Number(
            this.form.commissionOriginPos.replaceAll(",", ".")
          ),
          commissionDestinationPos: Number(
            this.form.commissionDestinationPos.replaceAll(",", ".")
          ),
          status: this.form.status ? "active" : "inactive"
        })
      );
      MainAppController.showMessageModal(
        new ModalMessageEntities({
          title: `“${this.form.configName}” Berhasil Diperbarui`,
          message: "Perubahan konfigurasi COD telah tersimpan",
          textSuccess: "OK",
          onSubmit: () => this.onCloseSuccess(),
          image: "badge-success",
          imageWidth: "32",
          imageHeight: "32"
        })
      );
    } catch (err) {
      MainAppController.showErrorMessage(
        parsingErrorResponse(err, `Perubahan “${this.form.configName}” Gagal !`, () =>
          this.submitDataPayload()
        )
      );
      console.log(err);
    }
    MainAppController.closeLoading();
  }

  form: Form = {
    configId: 0,
    configName: "",
    configDescription: "",
    typeCodPrice: "",
    products: [],
    insurance: {
      name: "",
      value: ""
    },
    cities: [],
    priceMinimal: "",
    priceMaximal: "",
    minCommissionCODOriginPOS: "",
    minCommissionCODDestinationPOS: "",
    isDfod: false,
    precentageCodFee: "",
    minimalCodFee: "",
    commissionOriginPos: "",
    commissionDestinationPos: "",
    status: false
  };

  get insurances() {
    return [
      {
        name: "Ya",
        value: "yes"
      },
      {
        name: "Tidak",
        value: "no"
      }
    ];
  }

  fetchCity(search: string) {
    LocationController.getCityList({
      search: search,
      status: "active",
      page: 1,
      limit: 10
    });
  }

  filterCity = debounce((search: string) => {
    if (search.length > 2 || !search) this.fetchCity(search);
  }, 250);

  get cityOptions() {
    return [
      {
        name: "Semua",
        value: "ALL"
      },
      ...LocationController.cityData.cityData.map(e => ({
        name: `${e.code} - ${e.name}`,
        value: `${e.code}`
      }))
    ];
  }

  get isLoadingCity() {
    return LocationController.isLoading;
  }

  formatInputNumberAbleZero(value: string) {
    return formatInputNumberOnly(value, true);
  }

  get priceMinimalValidity() {
    const zero = Number(this.form.priceMinimal.replaceAll(".", "")) <= 0;
    const validate = (zero && this.form.isDfod)
    return {
      error:
        this.form.priceMinimal === "" || validate
          ? false
          : zero,
      errorCaption: "Minimal Harga Barang harus lebih dari 0"
    };
  }

  get priceMaximalValidity() {
    const zero = Number(this.form.priceMaximal.replaceAll(".", "")) <= 0;
    const validate = (zero && this.form.isDfod)
    return {
      error:
        this.form.priceMaximal === "" || validate
          ? false
          : zero,
      errorCaption: "Maksimal Harga Barang harus lebih dari 0"
    };
  }

  get priceMaximalSmallerThan() {
    const minimalPrice =
      this.form.priceMinimal === ""
        ? ""
        : Number(this.form.priceMinimal.replaceAll(".", ""));
    const maximalPrice =
      this.form.priceMaximal === ""
        ? ""
        : Number(this.form.priceMaximal.replaceAll(".", ""));
    return {
      error: minimalPrice > maximalPrice,
      errorCaption:
        "Maksimal Harga Barang harus lebih besar dari Minimal Harga Barang"
    };
  }

  formatInputFloat(value: string) {
    return formatInputDecimal(value);
  }

  formatFloat(value: string) {
    return value
      .replace(/[^0-9,.]+/g, "")
      .replace(/\.|\.,|,,|,\./g, ",")
      .replace(/,(\d+),/g, ",$1");
  }

  error = {
    percentageCodFee: {
      error: false,
      errorCaption: ""
    },
    commissionOriginPos: {
      error: false,
      errorCaption: ""
    },
    commissionOriginDestination: {
      error: false,
      errorCaption: ""
    }
  };

  validatePercentageCodFee(value: string) {
    const newValue = value === "" ? "" : Number(value.replace(/,/g, "."));
    if (newValue <= 0 && newValue !== "") {
      this.error.percentageCodFee.error = true;
      this.error.percentageCodFee.errorCaption =
        "Persentase Biaya COD harus lebih dari nol";
    } else if (newValue > 100) {
      this.error.percentageCodFee.error = true;
      this.error.percentageCodFee.errorCaption =
        "Persentase Biaya COD harus kurang dari 100%";
    } else {
      this.error.percentageCodFee.error = false;
      this.error.percentageCodFee.errorCaption = "";
    }
  }

  validateCommissionOriginPos(value: string) {
    const newValue = value === "" ? "" : Number(value.replace(/,/g, "."));
    if (newValue > 100) {
      this.error.commissionOriginPos.error = true;
      this.error.commissionOriginPos.errorCaption =
        "Persentase Komisi COD POS Asal harus kurang dari 100%";
    } else {
      this.error.commissionOriginPos.error = false;
      this.error.commissionOriginPos.errorCaption = "";
    }
  }

  validateCommissionOriginDestination(value: string) {
    const newValue = value === "" ? "" : Number(value.replace(/,/g, "."));
    if (newValue > 100) {
      this.error.commissionOriginDestination.error = true;
      this.error.commissionOriginDestination.errorCaption =
        "Persentase Komisi COD POS Tujuan harus kurang dari 100%";
    } else {
      this.error.commissionOriginDestination.error = false;
      this.error.commissionOriginDestination.errorCaption = "";
    }
  }

  onUpdateStatus() {
    this.form.status = !this.form.status;
  }

  onCloseSuccess() {
    MainAppController.closeMessageModal();
    this.answerLeavingPage = true;
    this.goBack();
  }

  convertCodType(value: string) {
    switch (value) {
      case "goods_price":
        return "Harga Barang";
      case "goods_price,total_tarif":
        return "Harga Barang + Ongkir";
      default:
        return "";
    }
  }

  get minCodFreeLargerThan() {
    const codFee =
      this.form.minimalCodFee === ""
        ? ""
        : Number(this.form.minimalCodFee.replaceAll(".", ""));

    const goodPrice =
      this.form.priceMinimal === ""
        ? ""
        : Number(this.form.priceMinimal.replaceAll(".", ""));
    return {
      error: this.form.isDfod ? false : codFee > goodPrice,
      errorCaption:
        "Minimal Biaya COD Tidak Boleh lebih dari Minimal Harga Barang"
    };
  }
}
