
import { Options, Vue } from "vue-class-component";
import OverlayPanel from "primevue/overlaypanel";
import { CodConfigurationController } from "@/app/ui/controllers/CodConfigurationController";
import checkRolePermission from "@/app/infrastructures/misc/common-library/CheckRolePermission";
import formatDate from "@/app/infrastructures/misc/common-library/FormatDate";
import { COD_RETAIL_CONFIGURATION } from "@/app/infrastructures/misc/RolePermission";
import { formatPriceIDR, IS_SENDER_ACCOUNT, parsingErrorResponse } from "@/app/infrastructures/misc/Utils";
import { DetailCodConfigurationRetail } from "@/domain/entities/CodConfiguration";
@Options({
  components: {
    OverlayPanel
  }
})
export default class Detail extends Vue {
  get getTitle() {
    return this.detailData.ccoName;
  }
  get isEditAble() {
    return (
      checkRolePermission(COD_RETAIL_CONFIGURATION.EDIT)
    );
  }
  // Get Data
  created() {
    this.getDetail();
  }

  get id(): number {
    return Number(this.$route.params.id);
  }

  get isAuth(): any {
    return this.$route?.meta?.isAuth;
  }

  detailData = new DetailCodConfigurationRetail();
  isLoading = false;
  isError = false;
  errorCause = "";

  async getDetail() {
    this.isLoading = true;
    try {
      this.detailData = await CodConfigurationController.getDetailCodConfigurationRetail(this.id);
      this.isError = false;
    } catch (error) {
      this.errorCause = parsingErrorResponse(error).type;
      this.isError = true;
    } finally {
      this.isLoading = false;
    }
  }

  // Handle Get  
  get convertCreatedAt() {
    return formatDate(this.detailData.ccoCreatedAt);
  }

  get convertUpdatedAt() {
    return formatDate(this.detailData.ccoUpdatedAt);
  }

  get convertMinPriceIDR() {
    return formatPriceIDR(this.detailData.ccoMinPrice)
  }

  get convertMaxPriceIDR() {
    return formatPriceIDR(this.detailData.ccoMaxPrice)
  }

  get convertMinPriceCOD() {
    return formatPriceIDR(this.detailData.ccoMinCodValue)
  }

  get handleTypeCod() {
    if(this.detailData.ccoCodType === "goods_price,total_tarif") {
        return "Harga Barang + Ongkir"
    } else if(this.detailData.ccoCodType === "good_price" || this.detailData.ccoCodType === "goods_price") {
        return "Harga Barang"
    } else {
        return ""
    }
  }

  // route navigation
  goBack() {
    this.$router.push(`/tarif/konfigurasi-cod-retail`);
  }

  goToEdit(id: any) {
    this.$router.push(`/tarif/konfigurasi-cod-retail/${id}/edit`);
  }

  formatDate(date: string) {
    return formatDate(date);
  }

  get isSenderAcount() {
    return IS_SENDER_ACCOUNT;
  }

  get convertMinCODOriginPOS() {
    return formatPriceIDR(this.detailData.ccoOriginMinCommission)
  }

  get convertMinCODDestinationPOS() {
    return formatPriceIDR(this.detailData.ccoDestinationMinCommission)
  }
}
